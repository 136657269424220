import { Catalogue } from '@ynap/api-utils';
import apiAction from '@ynap/api-fetch-action';

export default (partNumber, uid) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { country, language, langISO } = state.appState;
        const { services, application } = state.config.global;
        const { baseUrl, clientId } = services.commerce;
        const configState = state.config;
        const { uberToken } = state.auth;

        const options = {
            locale: langISO,
        };

        const config = { baseUrl, brandId: application.brandId, country, language, partNumber, clientId, configState, options, uberToken };

        const request = Catalogue.getDetailsByPartNumber(config);

        const pdpResponse = await dispatch(apiAction(request, 'PRODUCT_DETAILS', uid, { partNumber }));

        if (typeof window === 'undefined') {
            try {
                const { products } = pdpResponse.json;

                const skuProduct = products[0].productColours[0].sKUs[0].partNumber;

                await dispatch(
                    apiAction(Catalogue.getPromoCode({ ...config, options: { partnumber: skuProduct } }), 'PROMO_PRICE', uid, {
                        partNumber: partNumber,
                        reqTimeout: 3000,
                    }),
                );
            } catch (error) {
                console.error('Error fetching promo code', error);
            }
        }

        return pdpResponse;
    };
};

export const updatePromoPrice = (skuProduct, partNumber, uid) => {
    return async (dispatch, getState) => {
        const state = getState();
        const isPromoSaved = state?.pdp?.promoState?.responses?.[skuProduct];
        const hasFetchedAfterLogin = state?.pdp?.promoState?.hasFetchedAfterLogin;
        const { uberToken } = state.auth;

        if (!isPromoSaved || (isPromoSaved && uberToken && !hasFetchedAfterLogin)) {
            const { country, language, langISO } = state.appState;
            const { services, application } = state.config.global;
            const { baseUrl, clientId } = services.commerce;
            const configState = state.config;

            const options = {
                locale: langISO,
            };

            const config = { baseUrl, brandId: application.brandId, country, language, partNumber, clientId, configState, options, uberToken };

            try {
                await dispatch(
                    apiAction(Catalogue.getPromoCode({ ...config, options: { partnumber: partNumber } }), 'PROMO_PRICE', uid, {
                        partNumber: skuProduct,
                        reqTimeout: 3000,
                        hasFetchedAfterLogin: !!uberToken,
                    }),
                );
            } catch (error) {
                console.error('Error fetching promo code', error);
            }
        }
    };
};
