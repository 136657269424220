import { PROMO_PRICE_SUCCESS, PROMO_PRICE_FAILURE, PROMO_PRICE_REQUEST } from '../constants';

const initialState = {
    responses: null,
    loading: false,
    error: false,
    hasFetchedAfterLogin: false,
};

export default function response(state = initialState, action = {}) {
    switch (action.type) {
        case PROMO_PRICE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PROMO_PRICE_SUCCESS:
            if (action.body.MarketingSpotData && action.body.MarketingSpotData.length) {
                return {
                    ...state,
                    responses: {
                        ...state.responses,
                        [action.options.partNumber]: {
                            response: {
                                body: action.body,
                                headers: action.headers,
                                status: action.status,
                                path: action.uid,
                                partNumber: action.options.partNumber,
                            },
                        },
                    },
                    loading: false,
                    error: false,
                    hasFetchedAfterLogin: action?.options?.hasFetchedAfterLogin || state.hasFetchedAfterLogin,
                };
            }
            return {
                ...state,
                responses: {
                    ...state.responses,
                    ['error']: {
                        response: {
                            body: action.body,
                            headers: action.headers,
                            status: 404,
                            path: action.uid,
                        },
                    },
                },
                loading: false,
                error: true,
            };

        case PROMO_PRICE_FAILURE:
            return {
                ...state,
                responses: {
                    ...state.responses,
                    ['error']: {
                        response: {
                            body: action.body.error,
                            headers: action.headers,
                            status: action.status,
                            path: action.uid,
                        },
                    },
                },
                loading: false,
                error: true,
            };

        default:
            return state;
    }
}
