import apiAction, { multipleRequestApiAction } from '@ynap/api-fetch-action';
import { Catalogue, Content, getCoreMediaForwardDate, getWCSPreviewDate, Foundit } from '@ynap/api-utils';
import { constructUrl } from '@ynap/url-configuration';
import { raygun } from '@ynap/clientside-monitoring';
import querystring from 'query-string';
import url from 'url';
import { push } from 'connected-react-router';
import {
    SET_NO_SET_SCROLL_ON_PLP,
    SET_SCROLL_ON_PLP,
    UNSET_UPDATE_PAGENUMBER,
    UNSET_SCROLL,
    SET_POSITION,
    RESET_PLP_STATE,
    SET_OPTIMIZELY_ALGORITHMIC_SORT_READY,
    PRODUCT_LISTING_SET_SINGLE_FILTER,
    PRODUCT_LISTING_SET_MULTI_FILTER,
    PRODUCT_LISTING_REMOVE_SINGLE_FILTER,
    PRODUCT_LISTING_REMOVE_MULTI_FILTER,
    PRODUCT_LISTING_UN_SELECT_ALL,
} from '../constants';
import { resetLoading } from 'react-redux-loading-bar';

const { getCategoryBySeoPath, getSearchResultsByTerm, getProductSiblings } = Catalogue;
const { getContentByPage, getContentByUrl } = Content;
const { getFounditByPage } = Foundit;

export function fetchMore(categoryPath, options = {}, uid, previous, user, pageType) {
    return (dispatch, getState) => {
        const state = getState();
        const { country, langISO, language } = state.appState;
        const { services, application } = state.config.global;
        const { search = {}, plp = {} } = state.config.head || {};
        const { itemsPerPage = 48 } = search;
        const { maxPages = 2 } = plp.loadMore || {};
        const { brandId } = application;
        const { commerce } = services;
        const { baseUrl, clientId } = commerce;
        if (Array.isArray(options.facet)) {
            options.facet.sort();
        }

        let previewDate;
        if (options.previewDate) {
            previewDate = Date.parse(options.previewDate) && new Date(options.previewDate);
            if (previewDate) {
                options.previewDate = getWCSPreviewDate(previewDate);
            } else {
                delete options.previewDate;
            }
        }

        if (langISO) {
            options.locale = langISO; // locale valid only for WCS calls
        }

        let wcsRequest;
        if (pageType === 'SEARCH') {
            const searchOptions = { ...options, pageSize: itemsPerPage, locale: langISO };
            wcsRequest = getSearchResultsByTerm({ baseUrl, brandId, country, language, term: categoryPath, options: searchOptions, clientId });
        } else {
            wcsRequest = getCategoryBySeoPath({ baseUrl, brandId, country, path: categoryPath, options, clientId });
        }

        options.previous = previous;
        options.user = user;
        options.maxPages = maxPages;

        return dispatch(apiAction(wcsRequest, 'PRODUCT_LISTING_MORE', uid, options)).then(() => {
            //push history to the correct url
            dispatch(
                push(uid, {
                    pidOnlySkeleton: true,
                    fetchMore: true,
                    previous: previous,
                }),
            );
        });
    };
}

export function setPLPProducts(partNumber) {
    return (dispatch, getState) => {
        const state = getState();
        //no action if there is only one product list
        if (!state?.plp?.listing?.visibleProducts || state.plp.listing.visibleProducts.length <= 1) {
            return dispatch({
                type: SET_NO_SET_SCROLL_ON_PLP,
            });
        }

        let index;

        //find the product list which contains the given pid
        const listIndex = state.plp.listing.visibleProducts.findIndex((pl) => {
            index = pl.products.findIndex((product) => product.partNumber === partNumber);
            return index >= 0 ? true : false;
        });

        return dispatch({
            type: SET_SCROLL_ON_PLP,
            pl: state.plp.listing.visibleProducts[listIndex],
        });
    };
}

export function updatePageNumber() {
    return (dispatch, getState) => {
        const state = getState();
        const newPath = state?.plp?.listing?.visibleProducts[0].key;

        //push histor to the correct url
        dispatch(
            push(newPath, {
                pidOnlySkeleton: true,
            }),
        );

        return dispatch({
            type: UNSET_UPDATE_PAGENUMBER,
        });
    };
}

export function unsetSrcoll() {
    return (dispatch) => {
        return dispatch({
            type: UNSET_SCROLL,
        });
    };
}

export function setSrcoll() {
    return (dispatch) => {
        return dispatch({
            type: 'SET_SCROLL',
        });
    };
}

export function resetPLPState() {
    return (dispatch) => {
        return dispatch({
            type: RESET_PLP_STATE,
        });
    };
}

export function setWindowPosition(windowPosition) {
    return (dispatch) => {
        return dispatch({
            type: SET_POSITION,
            windowPosition,
        });
    };
}

const createRequest = (request, prefix, abortFn = resetLoading, signal) => ({
    request,
    prefix,
    required: false,
    externalRequest: true,
    options: { signal, reqTimeout: 3000 },
    abortFn,
});

export function getSeoPageRecs({ baseUrl, clientId, locale, country, numberOfLinks, categoryPath, brandId, facet, apiVersion }) {
    const esite = `${brandId}_${country}`;

    const queryParams = querystring.stringify({
        path: `/${categoryPath}`,
        esite,
        language: locale,
        facet: facet ?? undefined,
        length: numberOfLinks || 16,
        version: apiVersion || 'v4',
    });

    const url = `${baseUrl}seo-page-rec/get?${queryParams}`;

    return new Request(url, {
        headers: {
            'x-ibm-client-id': clientId,
        },
    });
}

// uid: unique identifier for request to enable easy state/API response comparison eg. current page path
export function fetchListing(categoryPath, options = {}, uid, overrides = [], user, originalPath, hostname = '') {
    return (dispatch, getState) => {
        const state = getState();

        const { controller } = state.plp?.listing || {};

        if (controller?.abort) {
            controller.abort();
        }

        const { country, language, locale, langISO } = state.appState;
        const { services, application, urlConfig } = state.config.global;
        const { founditApikeys = '', founditUrl = '' } = state.config.head?.plp || {};
        const { brandId } = application;
        const { commerce, content: contentApi } = services;
        const { baseUrl, clientId } = commerce;
        const { baseUrl: coremediaApibaseUrl, clientId: coremediaApiclientId } = contentApi;
        const { plp = {} } = state;
        const { content = { response: null } } = plp;
        const newController = typeof AbortController !== 'undefined' && new AbortController();
        const signal = newController?.signal;

        if (Array.isArray(options.facet)) {
            options.facet.sort();
        }

        let previewDate;
        if (options.previewDate) {
            previewDate = Date.parse(options.previewDate) && new Date(options.previewDate);
            if (previewDate) {
                options.previewDate = getWCSPreviewDate(previewDate);
            } else {
                delete options.previewDate;
            }
        }

        /* START Setup CoreMedia Request */
        let coremediaRequest;
        let pushContentRequest;
        let coremediaPageKey;
        const coreMediaOptions = {
            mv: 'gitMarkdown',
            noCommerce: true,
            f: ['ycos', 'journal'],
            ...(options.previewDate && {
                forwardDate: getCoreMediaForwardDate(previewDate),
            }),
        };

        if (originalPath) {
            coremediaRequest = getContentByUrl({
                pageUrl: originalPath,
                brandId,
                baseUrl: coremediaApibaseUrl,
                country,
                locale: langISO,
                clientId: coremediaApiclientId,
                options: coreMediaOptions,
                v2: true,
            });
            pushContentRequest = true;
            coremediaPageKey = categoryPath.split('/').join('-'); // Necessary for ContentHeader, think could go after full switch to contentbyurl?
            if (content.response && content.response.path === coremediaPageKey) {
                // Don't call if we have content for this url already
                pushContentRequest = false;
            }
        } else {
            //coremedia page key, it's hyphenized because page key is part of path in coremedia call
            //https://nonprod.ynap.biz/dev/dev02-stg/blueprint/servlet/contentbypage/store/mrporter_US/key/list-back_in_stock?langId=en_US
            let pathArray = categoryPath.split('/');

            const { 0: topLevelCategory, 1: subCategory, 2: thirdCategory } = pathArray;

            const override = overrides.indexOf(topLevelCategory) !== -1 || overrides.indexOf(subCategory) !== -1; // If true, we want to call coremedia at every depth of the category tree.
            if (override) {
                coremediaPageKey = pathArray.join('-');
            } else {
                coremediaPageKey = topLevelCategory + (subCategory ? `-${subCategory}` : '');
            }

            // Coremedia does not support _ in urls: convert to dashes
            coremediaPageKey = coremediaPageKey.replace(/_/g, '-');

            coremediaRequest = getContentByPage({
                pageKey: coremediaPageKey,
                brandId,
                baseUrl: coremediaApibaseUrl,
                country,
                language,
                clientId: coremediaApiclientId,
                options: coreMediaOptions,
                coreMediaLocale: langISO,
                v2: true,
            });

            /*
            Only Make content request if url path for top level and 2nd level categories has changed
            i.e. not query param changes or 3rd level category in pathname

            HOWEVER if /topLevel/secondLevel/ThirdLevel is requested we will not do the content request, but if the override is set we will do the content request for all levels.
            */
            const isTopLevelDifferent = content.response && topLevelCategory !== content.response.path.split('/')[0];
            const isSubCategoryDifferent = content.response && subCategory !== content.response.path.split('/')[1];

            if (!thirdCategory || override || isTopLevelDifferent || isSubCategoryDifferent) {
                pushContentRequest = true;
            }
        }
        /* END Setup CoreMedia Request */

        /* START Setup WCS Request */
        if (langISO) {
            options.locale = langISO; // locale valid only for WCS calls
        }

        const wcsRequest = getCategoryBySeoPath({ baseUrl, brandId, country, path: categoryPath, options, clientId, controller: newController });

        options.generateRedirectUrl = (location) => {
            /* istanbul ignore next */
            /* global __CLIENT__:true */

            //ssr returns the constructed url so the head is going to the correct url
            if (typeof __CLIENT__ === 'undefined' || !__CLIENT__) {
                const redirectLocation = url.parse(location);
                const newCategory = querystring.parse(redirectLocation.search).category;
                return `${constructUrl(urlConfig?.plp?.construct, {
                    LOCALE: locale,
                    SEO_URLKEYWORD: newCategory,
                })}`;
            }

            //client side returns original location so fetch can auto follow
            return location;
        };

        options.user = user;
        options.signal = signal;

        /* END Setup WCS Request */

        const requests = [{ request: wcsRequest, prefix: 'PRODUCT_LISTING', uid, options, controller: newController, abortFn: resetLoading }];

        if (pushContentRequest) {
            requests.push({
                request: coremediaRequest,
                coremediaPageKey: decodeURIComponent(coremediaPageKey),
                prefix: 'PRODUCT_LISTING_CONTENT',
                uid: categoryPath,
                required: false,
                options: {
                    reqTimeout: 5000,
                    signal: signal,
                },
                abortFn: resetLoading,
            });
        }

        const seoPageRecCountries = state?.config?.head?.plp?.seoPageRecCountries;
        const seoPageRecEnabled = seoPageRecCountries?.includes(country?.toUpperCase?.());

        if (seoPageRecEnabled) {
            const numberOfLinks = state?.config?.head?.plp?.seoPageRecLength;
            const apiVersion = state?.config?.head?.plp?.seoPageRecVersion;

            requests.push(
                createRequest(
                    getSeoPageRecs({
                        baseUrl,
                        clientId,
                        locale: options.locale,
                        country,
                        numberOfLinks,
                        categoryPath,
                        brandId,
                        facet: options.facet,
                        apiVersion,
                    }),
                    'PRODUCT_LISTING_SEOPAGERECS',
                    resetLoading,
                    signal,
                ),
            );
        } else {
            /* Fetch foundit data */
            const apiKey = (founditApikeys && getFounditApikey(locale, founditApikeys)) || '';
            if (apiKey && hostname && uid) {
                const pageUrl = `${hostname}${uid}`;
                requests.push(createRequest(getFounditByPage({ founditUrl, type: 'page', apiKey, pageUrl }), 'PRODUCT_LISTING_FOUNDIT', resetLoading, signal));
            }
        }

        return dispatch(multipleRequestApiAction(requests));
    };
}

export function fetchProductSiblings(partNumbers) {
    return (dispatch, getState) => {
        const state = getState();
        const { country, language, langISO } = state.appState;
        const { services, application } = state.config.global;
        const { clientId, baseUrl } = services.commerce;
        const request = getProductSiblings({ baseUrl, brandId: application.brandId, country, language, partNumbers, clientId, options: { locale: langISO } });
        return dispatch(apiAction(request, 'PRODUCT_SIBLINGS'));
    };
}

export function fetchSearchResults(rawTerm, options = {}, uid) {
    return (dispatch, getState) => {
        const state = getState();
        const { country, language, langISO } = state.appState;
        const { services, application } = state.config.global;
        const { search = {}, tonMenswear = {} } = state.config.head || {};
        const { itemsPerPage = 48 } = search;
        const { brandId } = application;
        const { commerce, content: contentApi } = services;
        const { baseUrl, clientId } = commerce;
        const { baseUrl: coremediaApibaseUrl, clientId: coremediaApiclientId } = contentApi;
        const { plp = {} } = state;
        const { content = { response: null } } = plp;

        if (Array.isArray(options.facet)) {
            options.facet.sort();
        }

        const searchPageKey = tonMenswear?.enabled ? getSearchPageKeyByGender(uid) : 'search';

        const coremediaRequest = getContentByPage({
            pageKey: searchPageKey,
            brandId,
            baseUrl: coremediaApibaseUrl,
            country,
            language,
            clientId: coremediaApiclientId,
            options: {
                mv: 'gitMarkdown',
                noCommerce: true,
                f: 'ycos',
            },
            coreMediaLocale: langISO,
            v2: true,
        });

        const searchOptions = { ...options, pageSize: itemsPerPage, locale: langISO };
        let term = rawTerm;

        try {
            term = encodeURIComponent(decodeURIComponent(decodeURIComponent(rawTerm)));
        } catch {
            raygun.helpers.agent('send', {
                error: new Error(`fetchSearchResults encodeURIComponent error`),
                tags: [`error-encodeURIComponent`, `encode double decode failed for search term`],
                customData: { rawTerm },
            });
        }

        const wcsRequest = getSearchResultsByTerm({ baseUrl, brandId, country, language, term, options: searchOptions, clientId });
        const requests = [
            {
                request: wcsRequest,
                prefix: 'PRODUCT_LISTING',
                uid,
                options: searchOptions,
            },
        ];

        if (!content.response || content.response.path.split('/')[0] !== 'search') {
            requests.push({
                request: coremediaRequest,
                prefix: 'PRODUCT_LISTING_CONTENT',
                uid: 'search',
                required: false,
                options: {
                    reqTimeout: 5000,
                },
            });
        }

        return dispatch(multipleRequestApiAction(requests));
    };
}

function getSearchPageKeyByGender(url) {
    return url?.includes('/shop/mens') ? 'search-mens' : 'search';
}

export const getFounditApikey = (locale, founditApikeys) => {
    return founditApikeys.reduce((result, keyPair) => {
        const [name, code] = keyPair.split(':');
        if (name === locale) result = code;
        return result;
    }, '');
};

export function setOptimizelyAlgorithmicSortReady(isReady) {
    return (dispatch) => {
        return dispatch({
            type: SET_OPTIMIZELY_ALGORITHMIC_SORT_READY,
            isReady,
        });
    };
}

export function setSingleFilter(filter) {
    return (dispatch) => {
        return dispatch({
            type: PRODUCT_LISTING_SET_SINGLE_FILTER,
            filter,
        });
    };
}

export function setMultiFilter(filter) {
    return (dispatch) => {
        return dispatch({
            type: PRODUCT_LISTING_SET_MULTI_FILTER,
            filter,
        });
    };
}

export function removeSingleFilter(filter) {
    return (dispatch) => {
        return dispatch({
            type: PRODUCT_LISTING_REMOVE_SINGLE_FILTER,
            filter,
        });
    };
}

export function removeMultiFilter(filter) {
    return (dispatch) => {
        return dispatch({
            type: PRODUCT_LISTING_REMOVE_MULTI_FILTER,
            filter,
        });
    };
}

export function unselectAllFilters(appliedValues) {
    return (dispatch) => {
        return dispatch({
            type: PRODUCT_LISTING_UN_SELECT_ALL,
            appliedValues,
        });
    };
}
